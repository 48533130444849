import * as React from "react"
import ArticleSection from "../components/ArticleSection/ArticleSection"
import DefaultPageHeader from "../components/DefaultPageHeader/DefaultPageHeader"
import Line from "../components/Line/Line"
import PageEndCTASection from "../components/PageEndCTASection/PageEndCTASection"
import PageTemplate from "../components/PageTemplate/PageTemplate"
import TricolorMorphDef from "../components/TricolorMorphDef/TricolorMorphDef"
import { PageComponent } from "../types/gatsby.types"
import { ColorPalette } from "../utils/colors.utils"
import './about.scss'

const PageAbout: PageComponent = props => {
  return (
    <PageTemplate
      {...props} // always include
      className="PageAbout"
      title="About" 
      description="About AxonDivision: a full-service digital agency based in Dublin. We help you and your business succeed in this great acceleration of digitalisation with our unique blend of expertise in design, data and technology."
      backgroundColor={ColorPalette.gray}
      foregroundColor={ColorPalette.white}
    >
      
      <TricolorMorphDef />

      <DefaultPageHeader
        id="AboutPageHeader"
        title="About"
      >
        <Line />
      </DefaultPageHeader>

      <ArticleSection noMaxWidth>
        <div className="PageAboutArticleColumnSplit">
          <div>
            <p>
            <strong>AxonDivision</strong> was formed in September 2022 as the Digital Division of Dovetail Consultancy Ltd and operates as a website design & development, digital media, and marketing agency in Ireland. With a clientele of organisations based around the world, AxonDivision is a full services digital agency offering clients a turnkey solution to their Digital requirements.
            </p>
            {/* <p><strong>AxonDivision</strong> was founded in April 2013 and operates as a website design & development, digital media, and marketing agency based in the heart of Dublin city. With a clientele of organisations based around the world, as well as local firms based right here in Dublin&nbsp;city.</p>
            <p>The company was renamed to <strong>AxonDivision</strong> from the original name "Viralbamboo" in May&nbsp;2021.</p> */}
            <br />
            <p>We are wholly Irish owned and are registered with the Irish companies registration&nbsp;organisation.</p>
            <br />
          </div>
          <div>
            <p><strong>Company No.</strong>: 480194</p>
            <p><strong>Registered Vat No.</strong>: IE97445780</p>
            {/* <br />
            <p><strong>Address</strong>: <br />AxonDivision, <br />133 Harold’s Cross, Dublin 6, <br />Republic of Ireland</p> */}
            <br />
            <p><strong>Office Landline</strong>: +353 87 9676601</p>
            <p><strong>Email</strong>: <a href="mailto:marketing@axondivision.com" title="Write us an email">marketing@axondivision.com</a></p>
          </div>
        </div>
      </ArticleSection>

      <PageEndCTASection preSelectTopic="websites" />

      <Line />
    </PageTemplate>
  )
}

export default PageAbout
